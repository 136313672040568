export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyNuxtDynamic: () => import('..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyLogo: () => import('..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
      LazyVuetifyLogo: () => import('..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => c.default || c),
      LazyAdminSettingsAdmColumnsAutoComplete: () => import('..\\components\\admin-settings\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/admin-settings-adm-columns-auto-complete" */).then(c => c.default || c),
      LazyAdminSettingsGlobalSettings: () => import('..\\components\\admin-settings\\global-settings.vue' /* webpackChunkName: "components/admin-settings-global-settings" */).then(c => c.default || c),
      LazyAdminSettingsModules: () => import('..\\components\\admin-settings\\modules.vue' /* webpackChunkName: "components/admin-settings-modules" */).then(c => c.default || c),
      LazyCatalogInventoryLocationDialog: () => import('..\\components\\catalog\\inventory-location-dialog.vue' /* webpackChunkName: "components/catalog-inventory-location-dialog" */).then(c => c.default || c),
      LazyCatalogWishlist: () => import('..\\components\\catalog\\wishlist.vue' /* webpackChunkName: "components/catalog-wishlist" */).then(c => c.default || c),
      LazyCmsComponentAdd: () => import('..\\components\\cms\\component-add.vue' /* webpackChunkName: "components/cms-component-add" */).then(c => c.default || c),
      LazyCmsComponentFieldEditDialog: () => import('..\\components\\cms\\component-field-edit-dialog.vue' /* webpackChunkName: "components/cms-component-field-edit-dialog" */).then(c => c.default || c),
      LazyCmsComponentFilterButton: () => import('..\\components\\cms\\component-filter-button.vue' /* webpackChunkName: "components/cms-component-filter-button" */).then(c => c.default || c),
      LazyCmsComponentTable: () => import('..\\components\\cms\\component-table.vue' /* webpackChunkName: "components/cms-component-table" */).then(c => c.default || c),
      LazyCmsContentAdd: () => import('..\\components\\cms\\content-add.vue' /* webpackChunkName: "components/cms-content-add" */).then(c => c.default || c),
      LazyCmsContentField: () => import('..\\components\\cms\\content-field.vue' /* webpackChunkName: "components/cms-content-field" */).then(c => c.default || c),
      LazyCmsContentsTable: () => import('..\\components\\cms\\contents-table.vue' /* webpackChunkName: "components/cms-contents-table" */).then(c => c.default || c),
      LazyCmsExpandingFilterButton: () => import('..\\components\\cms\\expanding-filter-button.vue' /* webpackChunkName: "components/cms-expanding-filter-button" */).then(c => c.default || c),
      LazyCmsFileInfo: () => import('..\\components\\cms\\file-info.vue' /* webpackChunkName: "components/cms-file-info" */).then(c => c.default || c),
      LazyCmsFolderAdd: () => import('..\\components\\cms\\folder-add.vue' /* webpackChunkName: "components/cms-folder-add" */).then(c => c.default || c),
      LazyCmsImageCropper: () => import('..\\components\\cms\\image-cropper.vue' /* webpackChunkName: "components/cms-image-cropper" */).then(c => c.default || c),
      LazyCmsSectionField: () => import('..\\components\\cms\\section-field.vue' /* webpackChunkName: "components/cms-section-field" */).then(c => c.default || c),
      LazyCommonAlertDialog: () => import('..\\components\\common\\alert-dialog.vue' /* webpackChunkName: "components/common-alert-dialog" */).then(c => c.default || c),
      LazyCommonBreadcrumbs: () => import('..\\components\\common\\breadcrumbs.vue' /* webpackChunkName: "components/common-breadcrumbs" */).then(c => c.default || c),
      LazyCommonCameraImageCapture: () => import('..\\components\\common\\camera-image-capture.vue' /* webpackChunkName: "components/common-camera-image-capture" */).then(c => c.default || c),
      LazyCommonColumnUserSettingDialog: () => import('..\\components\\common\\column-user-setting-dialog.vue' /* webpackChunkName: "components/common-column-user-setting-dialog" */).then(c => c.default || c),
      LazyCommonConfirm: () => import('..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => c.default || c),
      LazyCommonCurrencyInput: () => import('..\\components\\common\\currency-input.vue' /* webpackChunkName: "components/common-currency-input" */).then(c => c.default || c),
      LazyCommonCustomerButtons: () => import('..\\components\\common\\customer-buttons.vue' /* webpackChunkName: "components/common-customer-buttons" */).then(c => c.default || c),
      LazyCommonExpandingFilterButton: () => import('..\\components\\common\\expanding-filter-button.vue' /* webpackChunkName: "components/common-expanding-filter-button" */).then(c => c.default || c),
      LazyCommonFilterScanDialog: () => import('..\\components\\common\\filter-scan-dialog.vue' /* webpackChunkName: "components/common-filter-scan-dialog" */).then(c => c.default || c),
      LazyCommonForgotPasswordDialog: () => import('..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => c.default || c),
      LazyCommonFormInput: () => import('..\\components\\common\\form-input.vue' /* webpackChunkName: "components/common-form-input" */).then(c => c.default || c),
      LazyCommonImageCard: () => import('..\\components\\common\\image-card.vue' /* webpackChunkName: "components/common-image-card" */).then(c => c.default || c),
      LazyCommonImageDialog: () => import('..\\components\\common\\image-dialog.vue' /* webpackChunkName: "components/common-image-dialog" */).then(c => c.default || c),
      LazyCommonImageViewerDialog: () => import('..\\components\\common\\image-viewer-dialog.vue' /* webpackChunkName: "components/common-image-viewer-dialog" */).then(c => c.default || c),
      LazyCommonLeftMenu: () => import('..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => c.default || c),
      LazyCommonLoginCard: () => import('..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => c.default || c),
      LazyCommonNavigationBarsLogin: () => import('..\\components\\common\\navigation-bars-login.vue' /* webpackChunkName: "components/common-navigation-bars-login" */).then(c => c.default || c),
      LazyCommonNavigationBars: () => import('..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => c.default || c),
      LazyCommonNewImageDialog: () => import('..\\components\\common\\new-image-dialog.vue' /* webpackChunkName: "components/common-new-image-dialog" */).then(c => c.default || c),
      LazyCommonPageTitleSection: () => import('..\\components\\common\\page-title-section.vue' /* webpackChunkName: "components/common-page-title-section" */).then(c => c.default || c),
      LazyCommonPaymentCardHeader: () => import('..\\components\\common\\payment-card-header.vue' /* webpackChunkName: "components/common-payment-card-header" */).then(c => c.default || c),
      LazyCommonPaymentCard: () => import('..\\components\\common\\payment-card.vue' /* webpackChunkName: "components/common-payment-card" */).then(c => c.default || c),
      LazyCommonPaymentDialog: () => import('..\\components\\common\\payment-dialog.vue' /* webpackChunkName: "components/common-payment-dialog" */).then(c => c.default || c),
      LazyCommonPaymentForm: () => import('..\\components\\common\\payment-form.vue' /* webpackChunkName: "components/common-payment-form" */).then(c => c.default || c),
      LazyCommonPaymentMethodButtons: () => import('..\\components\\common\\payment-method-buttons.vue' /* webpackChunkName: "components/common-payment-method-buttons" */).then(c => c.default || c),
      LazyCommonPinLoginCard: () => import('..\\components\\common\\pin-login-card.vue' /* webpackChunkName: "components/common-pin-login-card" */).then(c => c.default || c),
      LazyCommonPinLoginKeyboard: () => import('..\\components\\common\\pin-login-keyboard.vue' /* webpackChunkName: "components/common-pin-login-keyboard" */).then(c => c.default || c),
      LazyCommonQuantityInput: () => import('..\\components\\common\\quantity-input.vue' /* webpackChunkName: "components/common-quantity-input" */).then(c => c.default || c),
      LazyCommonQuantityNumericInput: () => import('..\\components\\common\\quantity-numeric-input.vue' /* webpackChunkName: "components/common-quantity-numeric-input" */).then(c => c.default || c),
      LazyCommonRegisterCard: () => import('..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => c.default || c),
      LazyCommonScanItemDialog: () => import('..\\components\\common\\scan-item-dialog.vue' /* webpackChunkName: "components/common-scan-item-dialog" */).then(c => c.default || c),
      LazyCommonScrollTable: () => import('..\\components\\common\\scroll-table.vue' /* webpackChunkName: "components/common-scroll-table" */).then(c => c.default || c),
      LazyCommonSelectStoreDialog: () => import('..\\components\\common\\select-store-dialog.vue' /* webpackChunkName: "components/common-select-store-dialog" */).then(c => c.default || c),
      LazyCommonSwitchUser: () => import('..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => c.default || c),
      LazyCustomOrderAddComponentsDialog: () => import('..\\components\\custom-order\\add-components-dialog.vue' /* webpackChunkName: "components/custom-order-add-components-dialog" */).then(c => c.default || c),
      LazyCustomOrderAttributeListGrid: () => import('..\\components\\custom-order\\attribute-list-grid.vue' /* webpackChunkName: "components/custom-order-attribute-list-grid" */).then(c => c.default || c),
      LazyCustomOrderComponentGroupDialog: () => import('..\\components\\custom-order\\component-group-dialog.vue' /* webpackChunkName: "components/custom-order-component-group-dialog" */).then(c => c.default || c),
      LazyCustomOrderComponentsCard: () => import('..\\components\\custom-order\\components-card.vue' /* webpackChunkName: "components/custom-order-components-card" */).then(c => c.default || c),
      LazyCustomOrderDetailComponent: () => import('..\\components\\custom-order\\custom-order-detail-component.vue' /* webpackChunkName: "components/custom-order-detail-component" */).then(c => c.default || c),
      LazyCustomOrderDetailDialog: () => import('..\\components\\custom-order\\custom-order-detail-dialog.vue' /* webpackChunkName: "components/custom-order-detail-dialog" */).then(c => c.default || c),
      LazyCustomOrderDetail: () => import('..\\components\\custom-order\\custom-order-detail.vue' /* webpackChunkName: "components/custom-order-detail" */).then(c => c.default || c),
      LazyCustomOrderSummaryCard: () => import('..\\components\\custom-order\\custom-order-summary-card.vue' /* webpackChunkName: "components/custom-order-summary-card" */).then(c => c.default || c),
      LazyCustomOrderTable: () => import('..\\components\\custom-order\\custom-order-table.vue' /* webpackChunkName: "components/custom-order-table" */).then(c => c.default || c),
      LazyCustomOrderEmailPreviewDialog: () => import('..\\components\\custom-order\\email-preview-dialog.vue' /* webpackChunkName: "components/custom-order-email-preview-dialog" */).then(c => c.default || c),
      LazyCustomOrderUploadFile: () => import('..\\components\\custom-order\\upload-file.vue' /* webpackChunkName: "components/custom-order-upload-file" */).then(c => c.default || c),
      LazyCustomerCreditsAddInvoiceDialog: () => import('..\\components\\customer-credits\\add-invoice-dialog.vue' /* webpackChunkName: "components/customer-credits-add-invoice-dialog" */).then(c => c.default || c),
      LazyCustomerCreditsExpandedTable: () => import('..\\components\\customer-credits\\expanded-table.vue' /* webpackChunkName: "components/customer-credits-expanded-table" */).then(c => c.default || c),
      LazyCustomerCreditsInvoiceItemDetailDialog: () => import('..\\components\\customer-credits\\invoice-item-detail-dialog.vue' /* webpackChunkName: "components/customer-credits-invoice-item-detail-dialog" */).then(c => c.default || c),
      LazyCustomerCreditsSubmitCard: () => import('..\\components\\customer-credits\\submit-card.vue' /* webpackChunkName: "components/customer-credits-submit-card" */).then(c => c.default || c),
      LazyCustomerCreditsYourMemoDialog: () => import('..\\components\\customer-credits\\your-memo-dialog.vue' /* webpackChunkName: "components/customer-credits-your-memo-dialog" */).then(c => c.default || c),
      LazyDashboardSummaryTable: () => import('..\\components\\dashboard\\dashboard-summary-table.vue' /* webpackChunkName: "components/dashboard-summary-table" */).then(c => c.default || c),
      LazyDashboardTable: () => import('..\\components\\dashboard\\dashboard-table.vue' /* webpackChunkName: "components/dashboard-table" */).then(c => c.default || c),
      LazyDashboardDayPicker: () => import('..\\components\\dashboard\\day-picker.vue' /* webpackChunkName: "components/dashboard-day-picker" */).then(c => c.default || c),
      LazyDashboardFilterToggleButtons: () => import('..\\components\\dashboard\\filter-toggle-buttons.vue' /* webpackChunkName: "components/dashboard-filter-toggle-buttons" */).then(c => c.default || c),
      LazyDashboardGraphCard: () => import('..\\components\\dashboard\\graph-card.vue' /* webpackChunkName: "components/dashboard-graph-card" */).then(c => c.default || c),
      LazyDashboardMonthPicker: () => import('..\\components\\dashboard\\month-picker.vue' /* webpackChunkName: "components/dashboard-month-picker" */).then(c => c.default || c),
      LazyDashboardTableCard: () => import('..\\components\\dashboard\\table-card.vue' /* webpackChunkName: "components/dashboard-table-card" */).then(c => c.default || c),
      LazyIntegrationsSquareConfiguration: () => import('..\\components\\integrations\\square-configuration.vue' /* webpackChunkName: "components/integrations-square-configuration" */).then(c => c.default || c),
      LazyIntegrationsSquareConnectTerminal: () => import('..\\components\\integrations\\square-connect-terminal.vue' /* webpackChunkName: "components/integrations-square-connect-terminal" */).then(c => c.default || c),
      LazyIntegrationsSquareNamingTerminal: () => import('..\\components\\integrations\\square-naming-terminal.vue' /* webpackChunkName: "components/integrations-square-naming-terminal" */).then(c => c.default || c),
      LazyIntegrationsSquareTerminals: () => import('..\\components\\integrations\\square-terminals.vue' /* webpackChunkName: "components/integrations-square-terminals" */).then(c => c.default || c),
      LazyLayawayCard: () => import('..\\components\\layaway\\layaway-card.vue' /* webpackChunkName: "components/layaway-card" */).then(c => c.default || c),
      LazyLayawayDetailDialog: () => import('..\\components\\layaway\\layaway-detail-dialog.vue' /* webpackChunkName: "components/layaway-detail-dialog" */).then(c => c.default || c),
      LazyLayawayPaymentsDialog: () => import('..\\components\\layaway\\layaway-payments-dialog.vue' /* webpackChunkName: "components/layaway-payments-dialog" */).then(c => c.default || c),
      LazyNewSaleAddCustomerEvent: () => import('..\\components\\new-sale\\add-customer-event.vue' /* webpackChunkName: "components/new-sale-add-customer-event" */).then(c => c.default || c),
      LazyNewSaleAddCustomerShippingDialog: () => import('..\\components\\new-sale\\add-customer-shipping-dialog.vue' /* webpackChunkName: "components/new-sale-add-customer-shipping-dialog" */).then(c => c.default || c),
      LazyNewSaleAddEditCustomerDialog: () => import('..\\components\\new-sale\\add-edit-customer-dialog.vue' /* webpackChunkName: "components/new-sale-add-edit-customer-dialog" */).then(c => c.default || c),
      LazyNewSaleCardPaymentDialog: () => import('..\\components\\new-sale\\card-payment-dialog.vue' /* webpackChunkName: "components/new-sale-card-payment-dialog" */).then(c => c.default || c),
      LazyNewSaleCartItemDetailDialog: () => import('..\\components\\new-sale\\cart-item-detail-dialog.vue' /* webpackChunkName: "components/new-sale-cart-item-detail-dialog" */).then(c => c.default || c),
      LazyNewSaleCheckoutDialog: () => import('..\\components\\new-sale\\checkout-dialog.vue' /* webpackChunkName: "components/new-sale-checkout-dialog" */).then(c => c.default || c),
      LazyNewSaleCustomerDetailDialog: () => import('..\\components\\new-sale\\customer-detail-dialog.vue' /* webpackChunkName: "components/new-sale-customer-detail-dialog" */).then(c => c.default || c),
      LazyNewSaleCustomerInfoDialog: () => import('..\\components\\new-sale\\customer-info-dialog.vue' /* webpackChunkName: "components/new-sale-customer-info-dialog" */).then(c => c.default || c),
      LazyNewSaleCustomerNoteDialog: () => import('..\\components\\new-sale\\customer-note-dialog.vue' /* webpackChunkName: "components/new-sale-customer-note-dialog" */).then(c => c.default || c),
      LazyNewSaleCustomerOrderHistory: () => import('..\\components\\new-sale\\customer-order-history.vue' /* webpackChunkName: "components/new-sale-customer-order-history" */).then(c => c.default || c),
      LazyNewSaleItemsToBuy: () => import('..\\components\\new-sale\\items-to-buy.vue' /* webpackChunkName: "components/new-sale-items-to-buy" */).then(c => c.default || c),
      LazyNewSalePaymentCard: () => import('..\\components\\new-sale\\payment-card.vue' /* webpackChunkName: "components/new-sale-payment-card" */).then(c => c.default || c),
      LazyNewSaleSelectCustomerDialog: () => import('..\\components\\new-sale\\select-customer-dialog.vue' /* webpackChunkName: "components/new-sale-select-customer-dialog" */).then(c => c.default || c),
      LazyNewSaleSummaryCalculations: () => import('..\\components\\new-sale\\summary-calculations.vue' /* webpackChunkName: "components/new-sale-summary-calculations" */).then(c => c.default || c),
      LazyNewSaleSummaryCard: () => import('..\\components\\new-sale\\summary-card.vue' /* webpackChunkName: "components/new-sale-summary-card" */).then(c => c.default || c),
      LazyNewSaleSummaryRepairCard: () => import('..\\components\\new-sale\\summary-repair-card.vue' /* webpackChunkName: "components/new-sale-summary-repair-card" */).then(c => c.default || c),
      LazyOrdersOrderCard: () => import('..\\components\\orders\\order-card.vue' /* webpackChunkName: "components/orders-order-card" */).then(c => c.default || c),
      LazyOrdersOrderDetailDialog: () => import('..\\components\\orders\\order-detail-dialog.vue' /* webpackChunkName: "components/orders-order-detail-dialog" */).then(c => c.default || c),
      LazyOrdersOrderImagesDialog: () => import('..\\components\\orders\\order-images-dialog.vue' /* webpackChunkName: "components/orders-order-images-dialog" */).then(c => c.default || c),
      LazyOrdersOrderNoteDialog: () => import('..\\components\\orders\\order-note-dialog.vue' /* webpackChunkName: "components/orders-order-note-dialog" */).then(c => c.default || c),
      LazyProductsCustomItemDetail: () => import('..\\components\\products\\custom-item-detail.vue' /* webpackChunkName: "components/products-custom-item-detail" */).then(c => c.default || c),
      LazyProductsProductDetail: () => import('..\\components\\products\\product-detail.vue' /* webpackChunkName: "components/products-product-detail" */).then(c => c.default || c),
      LazyProductsProductImage: () => import('..\\components\\products\\product-image.vue' /* webpackChunkName: "components/products-product-image" */).then(c => c.default || c),
      LazyRegisterAdjustmentDialog: () => import('..\\components\\register\\adjustment-dialog.vue' /* webpackChunkName: "components/register-adjustment-dialog" */).then(c => c.default || c),
      LazyRegisterBalanceCard: () => import('..\\components\\register\\balance-card.vue' /* webpackChunkName: "components/register-balance-card" */).then(c => c.default || c),
      LazyRegisterFilterCardDatePicker: () => import('..\\components\\register\\filter-card-date-picker.vue' /* webpackChunkName: "components/register-filter-card-date-picker" */).then(c => c.default || c),
      LazyRegisterOpenCloseRegisterDialog: () => import('..\\components\\register\\open-close-register-dialog.vue' /* webpackChunkName: "components/register-open-close-register-dialog" */).then(c => c.default || c),
      LazyRegisterPrintDailyDialog: () => import('..\\components\\register\\print-daily-dialog.vue' /* webpackChunkName: "components/register-print-daily-dialog" */).then(c => c.default || c),
      LazyRegisterIconButton: () => import('..\\components\\register\\register-icon-button.vue' /* webpackChunkName: "components/register-icon-button" */).then(c => c.default || c),
      LazyRepairCenterTable: () => import('..\\components\\repair-center\\repair-center-table.vue' /* webpackChunkName: "components/repair-center-table" */).then(c => c.default || c),
      LazyRepairCheckoutDialog: () => import('..\\components\\repair-center\\repair-checkout-dialog.vue' /* webpackChunkName: "components/repair-checkout-dialog" */).then(c => c.default || c),
      LazyRepairDetailDialog: () => import('..\\components\\repair-center\\repair-detail-dialog.vue' /* webpackChunkName: "components/repair-detail-dialog" */).then(c => c.default || c),
      LazyRepairItemImagesDialog: () => import('..\\components\\repair-center\\repair-item-images-dialog.vue' /* webpackChunkName: "components/repair-item-images-dialog" */).then(c => c.default || c),
      LazyRepairCenterSummaryRepairCalculations: () => import('..\\components\\repair-center\\summary-repair-calculations.vue' /* webpackChunkName: "components/repair-center-summary-repair-calculations" */).then(c => c.default || c),
      LazyRepairCenterTicketDetails: () => import('..\\components\\repair-center\\ticket-details.vue' /* webpackChunkName: "components/repair-center-ticket-details" */).then(c => c.default || c),
      LazySharedCloseBtn: () => import('..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => c.default || c),
      LazySharedCustomButton: () => import('..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => c.default || c),
      LazySharedCustomCombo: () => import('..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => c.default || c),
      LazySharedDarkButton: () => import('..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => c.default || c),
      LazySharedFileUploader: () => import('..\\components\\shared\\file-uploader.vue' /* webpackChunkName: "components/shared-file-uploader" */).then(c => c.default || c),
      LazySharedFilterAutocompleteSelection: () => import('..\\components\\shared\\filter-autocomplete-selection.vue' /* webpackChunkName: "components/shared-filter-autocomplete-selection" */).then(c => c.default || c),
      LazySharedFilterCardElement: () => import('..\\components\\shared\\filter-card-element.vue' /* webpackChunkName: "components/shared-filter-card-element" */).then(c => c.default || c),
      LazySharedFilterCard: () => import('..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => c.default || c),
      LazySharedImageCropper: () => import('..\\components\\shared\\image-cropper.vue' /* webpackChunkName: "components/shared-image-cropper" */).then(c => c.default || c),
      LazySharedImageUploaderField: () => import('..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => c.default || c),
      LazySharedImageUploader: () => import('..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => c.default || c),
      LazySharedLabeledControlFields: () => import('..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => c.default || c),
      LazySharedLabeledControlLayout: () => import('..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => c.default || c),
      LazySharedNoteCardLayout: () => import('..\\components\\shared\\note-card-layout.vue' /* webpackChunkName: "components/shared-note-card-layout" */).then(c => c.default || c),
      LazySharedPrimaryButton: () => import('..\\components\\shared\\primary-button.vue' /* webpackChunkName: "components/shared-primary-button" */).then(c => c.default || c),
      LazySharedPrimaryTitle: () => import('..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => c.default || c),
      LazySharedRefundDialog: () => import('..\\components\\shared\\refund-dialog.vue' /* webpackChunkName: "components/shared-refund-dialog" */).then(c => c.default || c),
      LazySharedSecondaryButton: () => import('..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => c.default || c),
      LazySharedSimpleActionButton: () => import('..\\components\\shared\\simple-action-button.vue' /* webpackChunkName: "components/shared-simple-action-button" */).then(c => c.default || c),
      LazySharedSquareCheckoutDialog: () => import('..\\components\\shared\\square-checkout-dialog.vue' /* webpackChunkName: "components/shared-square-checkout-dialog" */).then(c => c.default || c),
      LazySharedSquareSelectTerminalDialog: () => import('..\\components\\shared\\square-select-terminal-dialog.vue' /* webpackChunkName: "components/shared-square-select-terminal-dialog" */).then(c => c.default || c),
      LazySharedSummaryScrollTable: () => import('..\\components\\shared\\summary-scroll-table.vue' /* webpackChunkName: "components/shared-summary-scroll-table" */).then(c => c.default || c),
      LazySharedSvgIconButton: () => import('..\\components\\shared\\svg-icon-button.vue' /* webpackChunkName: "components/shared-svg-icon-button" */).then(c => c.default || c),
      LazySharedTreeListRow: () => import('..\\components\\shared\\tree-list-row.vue' /* webpackChunkName: "components/shared-tree-list-row" */).then(c => c.default || c),
      LazyCommonMyProfileEditFormCard: () => import('..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => c.default || c),
      LazyCommonMyProfileGeneralInfoCard: () => import('..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => c.default || c),
      LazyCommonMyProfilePasswordChangeCard: () => import('..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => c.default || c),
      LazyCommonOrderDetailCard: () => import('..\\components\\common\\order\\detail-card.vue' /* webpackChunkName: "components/common-order-detail-card" */).then(c => c.default || c),
      LazyNewSaleCustomerEventsTab: () => import('..\\components\\new-sale\\customer-detail-tabs\\customer-events-tab.vue' /* webpackChunkName: "components/new-sale-customer-events-tab" */).then(c => c.default || c),
      LazyNewSaleCustomerMainInfoTab: () => import('..\\components\\new-sale\\customer-detail-tabs\\customer-main-info-tab.vue' /* webpackChunkName: "components/new-sale-customer-main-info-tab" */).then(c => c.default || c),
      LazyNewSaleCustomerShippingInfoTab: () => import('..\\components\\new-sale\\customer-detail-tabs\\customer-shipping-info-tab.vue' /* webpackChunkName: "components/new-sale-customer-shipping-info-tab" */).then(c => c.default || c),
      LazyReportsSaleReportsCustomerSaleDialog: () => import('..\\components\\reports\\sale-reports\\customer-sale-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-customer-sale-dialog" */).then(c => c.default || c),
      LazyReportsSaleReportsItemGroupDetailDialog: () => import('..\\components\\reports\\sale-reports\\item-group-detail-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-item-group-detail-dialog" */).then(c => c.default || c),
      LazyReportsSaleReportsSalesByItems: () => import('..\\components\\reports\\sale-reports\\sales-by-items.vue' /* webpackChunkName: "components/reports-sale-reports-sales-by-items" */).then(c => c.default || c),
      LazyReportsSaleReportsSalesDetails: () => import('..\\components\\reports\\sale-reports\\sales-details.vue' /* webpackChunkName: "components/reports-sale-reports-sales-details" */).then(c => c.default || c),
      LazyReportsSaleReportsSalesHistory: () => import('..\\components\\reports\\sale-reports\\sales-history.vue' /* webpackChunkName: "components/reports-sale-reports-sales-history" */).then(c => c.default || c),
      LazyReportsTransactionsReportStoreTransactions: () => import('..\\components\\reports\\transactions-report\\store-transactions.vue' /* webpackChunkName: "components/reports-transactions-report-store-transactions" */).then(c => c.default || c)
  }

  const prefixes = ["frontend","nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
